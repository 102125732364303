import { MenuFoldOutlined, MenuUnfoldOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Badge, Button, Dropdown, Select, Typography } from 'antd';
import { isMyNuspireDev } from 'components/feature-flags';
import { NotificationsButton } from 'components/notifications/notifications';
import MyNuspireSVG from 'components/nuspire/nu-icon/svgs/my-nuspire-logo';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import UserMenu from 'components/user-menu';
import { config } from 'config';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { IClient, IUser } from 'types';
import { mixpanelTrack } from '../../../utils/mixpanel/mixpanel-track';
import { AdminDrawerAndButton } from '../../admin/admin-drawer';
import { useAuthContext } from '../../auth-context';
import { useClientContext } from '../../client-context-provider';
import { DebugDrawerAndButton, useDebugRequests } from '../../debug';
import { EmptyState, NuButton } from '../../nuspire';
import { useReleaseNotesContext } from './release-notes-notification';
import { useMyNuspireThemeContext } from 'components/theme';
import pdiLogoLight from '../../../public/pdi_logo_light.png';
import pdiLogoDark from '../../../public/pdi_logo_dark.png';

const TopNavRoot = styled.div`
  height: 64px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.token.colorBgContainer};
  ${(p) =>
    ['light', 'dark'].includes(p.theme.mode)
      ? `border-bottom: 1px solid ${p.theme.token.colorBorder};`
      : `border-bottom: 1px solid ${p.theme.token.colorBgLayout};`}

  .ant-dropdown-trigger {
    padding-bottom: 0; // hack
  }
`;

const LeftAligned = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
const RightItems = styled.div`
  display: flex;

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`;
const LogoContainer = styled.div`
  margin: 0 24px 0 14px;
  display: flex;
  align-items: center;
  a {
    color: ${(p) => p.theme.token.colorText};
  }
`;

const PngLogo = styled.img`
  height: 20px;
`;

function Logo() {
  const { theme } = useMyNuspireThemeContext();

  const logo = (() => {
    if (theme === 'light' || theme === 'dark') return <MyNuspireSVG />;

    if (theme === 'pdi-dark') return <PngLogo src={pdiLogoDark} />;

    return <PngLogo src={pdiLogoLight} />;
  })();

  return (
    <LogoContainer data-intercom-target="mynuspire-logo">
      <Link
        to="/"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {logo}
      </Link>
    </LogoContainer>
  );
}

interface ClientContextSwitcherProps {
  currentClient: IClient;
  user: IUser;
}

const SEARCH_CLIENTS = gql`
  query ClientSwitcherSearch($clientId: String, $queryString: String) {
    clientSearch(clientId: $clientId, queryString: $queryString) {
      items {
        id
        name
      }
    }
  }
`;

function ClientContextSwitcher(props: ClientContextSwitcherProps) {
  const { currentClient } = props;
  const navigate = useNavigate();

  // ====== State Management ======
  const [queryString, setQueryString] = useState<string | undefined>(undefined); // debounced for the query.
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined); // immediately update to update the UI.

  // UI should update instantly
  const handleSearchValue = (val?: string) => {
    if (val?.length) {
      setSearchValue(val);
    } else {
      setSearchValue(undefined);
    }
  };

  // Refetch query only after user stops typing.
  const debouncedSearch = useCallback(
    debounce((newSearch: string | undefined) => {
      setQueryString(newSearch);
    }, 600),
    [],
  );

  // call debouncedSearch everytime searchValue changes.
  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue]);

  // Graphql Request
  const { data, loading } = useQuery(SEARCH_CLIENTS, { variables: { queryString } });
  const items = data?.clientSearch?.items;

  // Build Select Options
  const options = items?.map((i) => ({
    label: i.name,
    value: i.id,
  }));

  const selectedValue = {
    label: currentClient.name,
    value: currentClient.id,
  };

  const onClientSelect = (newId: string) => {
    navigate(`/${newId}`);
  };

  return (
    <Select
      style={{ minWidth: 500 }}
      showSearch
      value={selectedValue}
      options={options}
      onSearch={handleSearchValue}
      searchValue={searchValue}
      loading={loading}
      filterOption={false}
      labelInValue
      onChange={(selected) => {
        onClientSelect(selected.value);
      }}
      notFoundContent={
        loading ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <EmptyState>No matching Clients</EmptyState>
        )
      }
    />
  );
}

function EmergencyIncidentButton() {
  const handleClick = () => {
    const newWindow = window.open(
      'https://www.nuspire.com/emergency-breach-response/',
      '_blank',
      'noopener, noreferrer',
    );
    if (newWindow) {
      newWindow.opener = null;
    }
    mixpanelTrack('emergency-breach-response');
  };

  return isMobile ? (
    <a href="tel:877-435-1640">
      <NuButton type="primary" style={{ width: '100%' }}>
        Emergency Incident Response
      </NuButton>
    </a>
  ) : (
    <NuButton type="primary" style={{ width: '100%' }} onClick={handleClick}>
      Emergency Incident Response
    </NuButton>
  );
}

function HelpCenterButton() {
  const releaseNotes = useReleaseNotesContext();
  const showBadge = releaseNotes.userHasUnreadReleaseNote;

  let icon = <QuestionCircleOutlined />;

  if (showBadge) icon = <Badge dot>{icon}</Badge>;

  return (
    <>
      <Dropdown
        trigger={['click']}
        menu={{
          items: [
            {
              key: 'help_articles',
              label: (
                <a href="https://docs.mynuspire.io/en" target="_blank" rel="noreferrer">
                  Help Articles
                </a>
              ),
            },
            {
              key: 'jsm_portal',
              label: (
                <a
                  href="https://mynuspire.okta.com/home/mynuspire_jiraservicemanagement_1/0oabf8nkquMy9ozhV5d7/alnbf8ucx97A7PDEa5d7"
                  target="_blank"
                  rel="noreferrer"
                >
                  Submit Feedback
                </a>
              ),
            },

            {
              key: 'release_notes',
              label: <Typography.Link onClick={releaseNotes.onOpenModal}>Release Notes</Typography.Link>,
            },
          ],
        }}
      >
        <NuButton type="text" icon={icon} shape="circle" data-intercom-target="nav-tour-help" size="large" />
      </Dropdown>
    </>
  );
}

interface MainMenuButtonProps {
  onClick: () => void;
  collapsed: boolean;
}

const CenteredButton = styled(Button).attrs({
  type: 'text',
})`
  display: grid;
  > span {
    align-self: center;
  }
`;

function MainMenuButton({ onClick, collapsed }: MainMenuButtonProps) {
  return <CenteredButton onClick={onClick} icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} />;
}

interface TopNavProps {
  onOpenNav: () => void;
  navCollapsed?: boolean;
}

export function TopNav({ onOpenNav, navCollapsed }: TopNavProps) {
  const { client } = useClientContext();
  const authContext = useAuthContext();
  const { canDebugRequests } = useDebugRequests();
  const location = useLocation();

  const { user } = authContext;

  const isAdminDrawerEnabled =
    config.isLocal || config.isDev || authContext.user?.isSuperUser || isMyNuspireDev({ user: authContext?.user });
  const isOnAdminPage = location.pathname.includes('/admin');

  return (
    <TopNavRoot>
      <LeftAligned>
        <MainMenuButton onClick={onOpenNav} collapsed={navCollapsed ?? false} />
        <Logo />
        {user && client && <ClientContextSwitcher user={user} currentClient={client} />}
      </LeftAligned>

      <RightItems>
        <EmergencyIncidentButton />
        <HelpCenterButton />
        <NotificationsButton />
        {canDebugRequests && <DebugDrawerAndButton />}
        {isAdminDrawerEnabled && user && !isOnAdminPage && <AdminDrawerAndButton user={user} />}
        <UserMenu />
      </RightItems>
    </TopNavRoot>
  );
}
