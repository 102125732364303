import { AuthContext } from 'components/auth-context';
import { config } from 'config';
import { useContext } from 'react';
import { ClientType, IAuthContext, IClient, IUser } from 'types';
import { ClientContext, IClientContext } from './client-context-provider';

/**
 * Features that we are flagging to decouple deployment from release.
 * Things that we may be iterating on, or sampling out to select users or clients,
 * or things that should only be enabled in certain environments (i.e., only show this in dev or staging).
 */
export const Features = {
  NECES: 'NECES',
  Nutron: 'Nutron',

  PdiTheme: 'PdiTheme',
};

/**
 * Returns an object keyed by feature name that holds the current state of the features (enabled or disabled) by boolean values.
 * @param featureNames The requested features to fetch status for.
 * @returns {{}|*}
 * @example
 *   const featureFlags = useFeatureFlag(Features.ThreatModelerGraphsVersionOne, Features.ThreatModelerGraphsVersionTwo);
 *   {
 *     featureFlags[Feature.ThreatModelerGraphsVersionOne]
 *     ? <div>Threat Modeler v1 Enabled</div>
 *     : null
 *   }
 */
export function useFeatureFlags(featureNames: string[]): { [key: string]: boolean } {
  const authContext = useContext(AuthContext);
  const clientContext = useContext(ClientContext);
  if (!authContext) {
    return {};
  }

  const featureFlags = featureNames.reduce((flags, feature) => {
    const enabled = isFeatureEnabled({
      featureName: feature,
      authContext,
      clientContext,
    });

    flags[feature] = enabled;

    return flags;
  }, {});

  return featureFlags;
}

/**
 * Returns a boolean flag representing the current enabled state of a feature.
 * @param featureName The name of the feature to use.
 * @returns {boolean|*} A boolean value that represents whether the feature is enabled or not.
 * @example
 *    const threatModelerGraphsVersionOneEnabled = useFeatureFlag(Features.ThreatModelerGraphsVersionOne);
 *   {
 *     threatModelerGraphsVersionOneEnabled
 *     ? <div>Threat Modeler v1 Enabled</div>
 *     : null
 *   }
 */
export function useFeatureFlag(featureName: string): boolean {
  const authContext = useContext(AuthContext);
  const clientContext = useContext(ClientContext);
  const enabled = isFeatureEnabled({
    featureName,
    authContext,
    clientContext,
  });

  return enabled;
}

export function isFeatureEnabled(args: {
  featureName: string;
  authContext: IAuthContext;
  clientContext: IClientContext;
}) {
  const { featureName, authContext, clientContext } = args;

  if (!authContext) {
    return false;
  }

  // Check to see if the user has access via feature management.
  const featureEnabled = Boolean(authContext?.user?.enabledFeatures?.[featureName]);
  // either feature is a beta feature and user is beta or this feature has been
  // explicitely added to the client.
  return featureEnabled;
}

export function useFeatureFlagAuthContext(featureName: string): {
  enabled: boolean;
  authContext: IAuthContext;
  clientContext: IClientContext;
} {
  const authContext = useContext(AuthContext);
  const clientContext = useContext(ClientContext);

  const enabled = isFeatureEnabled({
    featureName,
    authContext,
    clientContext,
  });

  return {
    enabled,
    authContext,
    clientContext,
  };
}

export function isChildOf(props: { client?: IClient; clientIds?: string[] }): boolean {
  const { client, clientIds = [] } = props;
  if (!client) return false;

  // if the user's client includes any of the top-level client ids identified,
  // then we will consider that this client is a child of those clients (i.e,. this is a managed subaru client underneath a top-level client).
  return clientIds.some((clientId) => {
    return client.path?.includes(clientId);
  });
}

export const isMyNuspireDev = (args: { user?: IUser }): boolean => {
  if (!args?.user || !args?.user?.email?.trim()) return false;
  const {
    user: { email: userEmail },
  } = args;

  return config.myNuspireDevs.includes(userEmail.toLowerCase());
};

export const isProgramClient = (args: { clientContext: IClientContext }) => {
  const { clientContext } = args;
  const isProgramClient = clientContext?.client?.type === ClientType.program; // NOTE: Most of the Subaru clients in dev either do not have a 'type' set or aren't set to 'program'
  const isChildOfSubaru = isChildOf({
    client: clientContext?.client,
    clientIds: ['ba696f93-8465-4d5d-ae5a-63b65fd3ffad'], // Subaru DT should be the parent, but does not exist in dev
  });

  return isProgramClient || isChildOfSubaru;
};
